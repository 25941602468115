import React from 'react';

const AviationText = () => {
  return (
    <div
      style={{
        
        fontFamily: 'Times New Roman, Times, serif',
        color: "#555555",
        fontSize: '22px',
        fontWeight: 'normal',
        lineHeight: '1.5',
      }}>

      <p style={{ marginBottom: '10px', fontSize: 22 }}>
        <strong>EU Adopts New Law to Decarbonize Aviation Sector with Sustainable Fuels</strong>
      </p>
      
      <p style={{ marginBottom: '10px' }}>
        The European Union has taken a major step towards decarbonizing the aviation sector through the adoption of a new regulation centered on the "RefuelEU Aviation" initiative. This initiative aims to increase the use of sustainable aviation fuels (SAF) and synthetic fuels, which have lower CO2 emissions than fossil fuels, in order to reduce the environmental impact of air travel.
      </p>

      <h2 style={{ marginBottom: '10px' }}><strong>Objectives of the New Legislation</strong></h2>

      <ul style={{ marginBottom: '10px' }}>
        <li>
          <strong>a) </strong>Increase demand for and supply of SAF and synthetic fuels.
        </li>
        <li>
          <strong>b) </strong>Put air transport on track to meet the EU's climate targets for 2030 and 2050.
        </li>
        <li>
          <strong>c) </strong>Address the current challenges of low supply and high prices of SAF.
        </li>
      </ul>

      <br />

      <h2 style={{ marginBottom: '10px' }}><strong>Provisions of the New Regulation
      </strong></h2>

      <p style={{ marginBottom: '10px' }}>
        Aviation fuel suppliers are obligated to ensure that a minimum share of the fuel provided to aircraft operators at EU airports comprises SAF or synthetic fuel. This requirement progressively increases until 2050, fostering a gradual transition towards greener fuel alternatives.       </p>

      <p style={{ marginBottom: '10px' }}>
        Additionally, aircraft operators are obligated to uplift at least 90% of their annual aviation fuel requirement at EU airports, avoiding "tankering" practices that contribute to additional emissions. Eligible SAF and synthetic fuels are defined, encompassing certified biofuels, renewable fuels of non-biological origin (e.g., renewable hydrogen), and recycled carbon aviation fuels. </p>

      <p style={{ marginBottom: '10px' }}>
        Furthermore, the regulation establishes rules for competent authorities to enforce compliance, complete with provisions for fines. Notably, a Union labeling scheme on environmental performance is introduced for aircraft operators utilizing SAF, empowering consumers to make informed choices and promoting environmentally conscious flight options. Additionally, data collection and reporting obligations are imposed on fuel suppliers and aircraft operators to monitor the regulation's impact.</p>

      <h2 style={{ marginBottom: '10px' }}><strong>Impact of the New Regulation</strong></h2>

      <p style={{ marginBottom: '10px' }}>
        The new regulation is expected to have a significant impact on the aviation sector by:
      </p>

      <ul style={{ marginBottom: '10px' }}>
        <li>
          <strong>a) </strong>Reducing the CO2 emissions from air travel.
        </li>
        <li>
          <strong>b) </strong>Stimulating the development and production of SAF and synthetic fuels.
        </li>
        <li>
          <strong>c) </strong>Creating a level playing field for airlines that use sustainable fuels.
        </li>
        <li>
          <strong>d) </strong>Helping the EU to meet its climate targets.
        </li>
      </ul>
    </div>
  );
};

export default AviationText;
