import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const SustainableMobilityHeaderText = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <h1
      style={{
        position: 'absolute',
        color: 'white',
        top: 571,
        left: isSmallScreen ? 30 : 85,
        right: 0,
        fontFamily: 'Montserrat',
        fontSize: isSmallScreen ? 30 : '60px',
        fontWeight: 400,
        lineHeight: '73px',
        letterSpacing: '0em',
        textAlign: 'left',
        width: isSmallScreen && 400,
      }}>
      Green Transportation: Navigating the Transition{' '}
      <span
        style={{
          fontFamily: 'Montserrat',
          fontSize: isSmallScreen ? 30 : '60px',
          fontWeight: 100,
          fontStyle: 'italic',
          lineHeight: '73px',
          letterSpacing: '0em',
        }}>
        Sustainable Mobility
      </span>
    </h1>
  );
};

export default SustainableMobilityHeaderText;
