/* eslint-disable no-unused-vars */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { idx } from '../../../utils/Lunar';

import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import line from './Line 13.png';

import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

const Search = ({ inputColor, isSmallScreen }) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleSearch = event => {
    const query = event.target.value;
    setQuery(query);

    if (query.trim() !== '') {
      const searchResults = idx.search(query);
      setResults(searchResults);
    } else {
      setResults([]);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      navigate('/Results', { state: { results: results } }); // Pass results as an object
    }
  };

  return isSmallScreen ? (
    <Stack
      flexGrow={1}
      justifyContent={'center'}
      alignItems={'center'}
      style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}>
        <TextField
          id="input-with-sx"
          label="Search Here"
          variant="standard"
          value={query}
          onChange={event => {
            handleSearch(event);
          }}
          style={{
            width: 180,
            position: 'absolute',
            zIndex: 1000,
            left: 20,
            top: -12,
          }}
          InputProps={{
            sx: {
              color: inputColor,
              '& .MuiInputBase-root': {
                color: inputColor, // To change input text color
              },
            },
          }}
          InputLabelProps={{
            style: {
              color: inputColor,
              fontSize: '12px',
            },
          }}
        />
      </div>
      {results.length !== 0 && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            padding: 3,
            background: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(5px)',
            width: 180,
            zIndex: 999,
            marginTop: 4,
          }}>
          {results.map(result => (
            <div
              style={{
                padding: 5,
                justifyContent: 'normal',
                margin: 1,
              }}
              key={result.ref}>
              <Link to={result.ref}>
                <button
                  style={{
                    fontSize: '12px',
                    fontStyle: 'italic',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                  }}>
                  {result.ref}
                </button>
              </Link>
            </div>
          ))}
        </div>
      )}
    </Stack>
  ) : (
    <Stack flexGrow={1} justifyContent={'center'} alignItems={'center'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}>
        <SearchIcon
          sx={{ transform: 'translate(-10px, 10px)' }}
          style={{ color: inputColor }}
        />
        <TextField
          id="input-with-sx"
          label="Search Here"
          variant="standard"
          onChange={event => {
            handleSearch(event);
          }}
          onKeyDown={handleKeyPress}
          style={{ width: 630 }}
          sx={{
            border: 'ActiveBorder',
            '& .MuiTextField-root': {
              color: inputColor,
            },
            color: inputColor,
          }}
          InputLabelProps={{
            style: {
              color: inputColor,
            },
          }}
          inputProps={{
            style: {
              color: inputColor,
            },
          }}
        />
      </div>
      {results.length !== 0 && (
        <div
          style={{
            padding: 3,
            background: '#09283F',
            backdropFilter: 'blur(5px)',
            marginLeft: '1.6vw',
            width: 600,
            zIndex: 9999,
          }}>
          {results.map(result => (
            <div
              style={{
                padding: 5,
                justifyContent: 'normal',
                margin: 1,
              }}
              key={result.ref}>
              <Link to={result.ref}>
                <button
                  style={{
                    fontSize: '24px',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                    color: 'white',
                  }}>
                  {result.ref.split('/')[1] === 'practiceAreas'
                    ? 'Practice Areas'
                    : result.ref.split('/')[1].slice(0, 1).toUpperCase() +
                      result.ref.split('/')[1].slice(1)}
                </button>
                <p
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    fontFamily: 'Montserrat',
                    color: 'white',
                  }}>
                  {result.ref.split('/')[2] === 'whiteCollarCrime'
                    ? 'White-collar Crime'
                    : result.ref.split('/')[2] &&
                        result.ref.split('/')[2].includes('-')
                      ? result.ref.split('/')[2] &&
                        result.ref
                          .split('/')[2]
                          .split('-')
                          .map(
                            word => word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(' ')
                      : result.ref.split('/')[2] &&
                        result.ref
                          .split('/')[2]
                          .split(/(?=[A-Z])|[^a-zA-Z]+/)
                          .map(
                            word => word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(' ')}
                </p>
                <img src={line} alt="line" />
              </Link>
            </div>
          ))}
        </div>
      )}
    </Stack>
  );
};

Search.propTypes = {
  inputColor: PropTypes.string.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default Search;
