import PropTypes from 'prop-types';
import HoverableImageWith from '../HoverableImage/hvImgwith';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const TripleImageGrid = ({ images }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [firstImage, secondImage, thirdImage] = images;

  return (
    <Stack
      direction={isSmallScreen ? 'column' : 'row'}
      spacing={isSmallScreen ? 2 : 4}
      sx={{ marginBottom: '20px', marginTop: '20px' }}>
      <HoverableImageWith
        key={'Technology'}
        src={firstImage.img}
        width={isSmallScreen ? '100%' : '100%'}
        height={isSmallScreen ? 'auto' : '692px'}
        bigWidth={isSmallScreen ? '100%' : '750px'}
        text={firstImage.textLines}
        toPage={firstImage.toPage}
      />

      <Stack
        spacing={isSmallScreen ? 2 : 4}
        sx={{ width: isSmallScreen ? '100%' : 'auto' }}>
        <HoverableImageWith
          key={'Oil & Gas'}
          src={secondImage.img}
          width={isSmallScreen ? '100%' : '600px'}
          height={isSmallScreen ? 'auto' : '318px'}
          text={secondImage.textLines}
          toPage={secondImage.toPage}
        />

        <HoverableImageWith
          key={'Renewable Energy'}
          src={thirdImage.img}
          width={isSmallScreen ? '100%' : '600px'}
          height={isSmallScreen ? 'auto' : '318px'}
          text={thirdImage.textLines}
          toPage={thirdImage.toPage}
        />
      </Stack>
    </Stack>
  );
};

TripleImageGrid.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      textLines: PropTypes.arrayOf(PropTypes.string).isRequired,
      toPage: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TripleImageGrid;
