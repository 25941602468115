import React from 'react';

const SustainableMobilityText = () => {
  return (
    <div
      style={{
        fontSize: '22px',
        fontWeight: '400',
        lineHeight: '1.2',
        
        fontFamily: 'Times New Roman, Times, serif',
        color: "#555555"
      }}>

      <p style={{ marginBottom: '10px', fontSize: 22 }}>
        <strong>Green Transportation: Navigating the Transition to Sustainable Mobility</strong>
      </p>

      <p style={{ marginBottom: '20px' }}>
        The familiar roar of combustion engines is gradually being replaced by the quiet hum of electric motors, marking a significant shift in the transportation sector towards greener energy. This transition, driven by the urgent need to tackle climate change and air pollution, offers the promise of a cleaner and more sustainable future for mobility. However, like any technological revolution, the adoption of green transportation technologies presents both opportunities and challenges.
      </p>

      <h2 style={{ marginBottom: '20px' }}><strong>Environmental Benefits</strong></h2>

      <p style={{ marginBottom: '20px' }}>
        One of the most compelling arguments for green transportation is its undeniable environmental advantages. Electric vehicles (EVs) are notable for producing zero tailpipe emissions, resulting in a significant reduction in greenhouse gas (GHG) emissions compared to traditional gasoline-powered vehicles. Studies have shown that EVs emit 60-70% fewer GHGs over their lifetime, even when factoring in electricity generation. This translates to cleaner air, improved public health, and a reduced impact on global warming.
        <br /><br />
        Furthermore, transitioning to green transportation reduces our dependence on finite fossil fuels, addressing geopolitical and economic uncertainties associated with volatile oil prices. Additionally, the shift to electric vehicles contributes to quieter streets, creating a more peaceful and pleasant urban environment.
        <br /><br />
        Despite these environmental benefits, the transition to green transportation faces several challenges. Chief among them is the initial cost of EVs, which can be significantly higher than traditional gasoline-powered vehicles. While battery costs have been steadily decreasing, affordability remains a barrier for many consumers, particularly in low- and middle-income countries.
        <br /><br />
        Another challenge is the limited availability of charging infrastructure. The current network of charging stations is often inadequate, especially in rural areas and developing nations, leading to range anxiety among EV drivers and hindering widespread adoption.
        <br /><br />
        Moreover, the environmental impact of battery production and disposal raises concerns. The extraction of raw materials such as lithium and cobalt raises environmental and ethical issues, while proper battery disposal is essential to prevent environmental contamination.
      </p>

      <h2 style={{ marginBottom: '20px' }}><strong>Charting a Sustainable Path</strong></h2>

      <p style={{ marginBottom: '20px' }}>
        Despite these challenges, there are promising solutions on the horizon for sustainable transportation. Technological advancements are rapidly driving down the cost of EVs and batteries, making them more accessible to a broader range of consumers. Significant investments by governments and private companies in expanding charging infrastructure aim to alleviate range anxiety and build consumer confidence.        <br /><br />
        Furthermore, ongoing research into sustainable battery production and recycling methods seeks to minimize the environmental footprint of these critical components. By addressing these challenges proactively and fostering international collaboration, we can accelerate the transition to a cleaner and more sustainable future for transportation.
      </p>

      <h2 style={{ marginBottom: '20px' }}><strong>The Journey Ahead</strong></h2>

      <p style={{ marginBottom: '20px' }}>
        While the road to green transportation may have its obstacles, the destination – a cleaner, healthier, and more sustainable future for all – is undoubtedly worth the journey. By carefully weighing the benefits and challenges, investing in innovative solutions, and promoting international cooperation, we can navigate the complex landscape of green mobility. This ensures that future generations inherit a world where clean air and sustainable travel are not just aspirations but a lived reality.
      </p>

    </div>
  );
};

export default SustainableMobilityText;
