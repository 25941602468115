import React from 'react';

const InternationalFinanceText = () => {
  return (

    <>
      <div
        style={{
          fontSize: '22px',
          fontWeight: '400',
          lineHeight: '1.2',

          fontFamily: 'Times New Roman, Times, serif',
          color: "#555555"
        }}>

        <p style={{ marginBottom: '10px', fontSize: 22 }}>
          <strong>A Review of the Amended Egyptian Competition Law: Pre-Merger Control Regime</strong>
        </p>

        <h2><strong>INTRODUCTION</strong></h2><br />

        <p>
          On 29 December 2022, the Egyptian Competition Law no. 3 of 2005 (“COL”) was amended by Law no. 175 of 2022 (“CLA”) which introduced a long-awaited change to Egyptian merger-control procedures replacing the post-closing merger notification regime in place since 2008 with the pre-closing merger control regime (“Pre-Merger Regime”), and on 4 January 2023 the Egyptian Competition Authority (“ECA”) made a press-release confirming that any transactions closed after 29 December 2022 shall not be subjected to any kind of merger filing until the issuance of the COL executive regulations amendments which will clarify several fundamental matters for the Pre-Merger Regime to become enforceable, such as the methods for calculating financial threshold, and determining the relevant official fees for pre-merger notification.        </p>
        <br />

        <p>On 4 April 2024 the long-awaited COL Executive Regulations were amended by Prime Minister Decree no. 1120 for 2024 (“ER Amendments”) which declared the effective date for the application of the Pre-Merger Regime, the steps for filing the pre-merger notification, the required document/information, the methods for calculating financial threshold, and the official filing fees, as detailed in this report.</p>
        <br />

        <h2><strong>PRE-MERGER REGIME EFFECTIVE DATE</strong></h2>
        <br />

        <p>1 June 2024 is the official effective date for the application of the Pre-Merger Regime, this means that (i) any transaction closed prior to this date is not subjected to any kind of merger-control filing in Egypt even if the relevant threshold and requirements were met, and (ii) following this effective date, it will be mandatory for any future economic concentration that meets the relevant financial thresholds and requirements stipulated in the COL as amended to submit a pre-merger notification to the ECA and failing to comply with this obligation will result to the application of major financial penalties as demonstrated herein.</p>
        <br />

        <h2><strong>ECONOMIC CONCENTRATION</strong></h2>
        <br />

        <p>The Economic Concentration is defined in the COL as any change in control or material influence of one or more entities, resulting from any of the following:</p>
        <br />

        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>The merging of one or more entities with an existing entity that maintains a legal entity after the merger, or the establishment of a new entity through the merging of at least two previously independent entities that results in the termination of their legal personalities or any of their parts.</li>
          <br />
          <li>A direct or indirect acquisition by one or more entities of control or material influence over another entity or a part thereof by virtue of a contract, or through the purchase of securities or assets or other means; such acquisitions can take place individually or collectively.</li>
          <br />
          <li>The establishment of a joint venture, or the acquisition by two or more entities of an existing entity, for the purpose of establishing a joint venture that carries out economic activity independently and permanently.</li>
          <br />
        </ul>

        <h2><strong>MATERIAL INFLUENCE</strong></h2>
        <br />

        <p>The ER Amendments introduced the definition of Material Influence as the ability to cause a direct or indirect influence on another person’s policy, including its strategic decisions and commercial targets, and such influence specifically occurs in any of the following cases:</p>
        <br />

        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>An act that leads to owning 25% or more of the voting-rights, or shares/quotas of another person’s capital.</li>
          <br />
          <li>Owning less than 25% of the voting-rights, or shares/quotas of another person’s capital if it was paired with another elements that have an impact on its policy, specifically if:</li>
          <br />
          <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '40px' }}>
            <li>The percentage of the voting rights owned by the person compared to the rest of the voting rights which would allow to make an impact on the person’s policy and commercial target.</li>
            <br />
            <li>The existence of provisions in the articles of association, or shareholders agreement, or others, that grant the acquiring person(s) privileges such as preferred voting or veto rights.</li>
            <br />
            <li>The existence of mutual shareholders between the acquiring and acquired person.</li>
            <br />
            <li>The acquiring person has one or more representatives at the board of directors of the acquired person.</li>
            <br />
          </ul>
        </ul>

        <p>Material Influence does not occur with owning less than 10% of the total voting rights or quotas/shares in another person, unless the acquiring entity is one of the biggest three shareholders in the acquired person.</p>
        <br />

        <h2><strong>FINANCIAL THRESHOLDS TRIGGERING PRE-MERGER NOTIFICATION</strong></h2>
        <br />

        <p>The CLA sets two separate financial thresholds that trigger the obligation to file a notification under the Pre-Merger Regime:</p>
        <br />

        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>The cumulative value of annual Egyptian turnover or of assets located in Egypt for all Concerned Parties of the economic concentration exceeds EGP 900 million based on the latest approved consolidated annual financial statement and if the Egyptian annual turnover of at least two Concerned Parties (each one separately) exceeds EGP 200 million based on the latest approved consolidated annual financial statement.</li>
          <br />
          Or
          <br /><br />
          <li>The cumulative value of annual worldwide turnover or assets located worldwide for all Concerned Parties of the Economic Concentration exceeds EGP 7.5 billion based on the latest approved consolidated annual financial statements, if the value of the Egyptian turnover for at least one party exceeds EGP 200 million based on its latest approved consolidated annual financial statement.</li>
          <br />
        </ul>

        <p>The ER Amendments defined the “Concerned Parties” as, natural persons, juridical persons, economic entities, unions, associations, financial groups, and personal groups regardless its establishment method which participate in the Economic Concentration along with their related parties, and;</p>
        <br />
        <p>“Related Parties” as parties composed of two or more persons, each of them has an independent legal personality, where the majority of stocks or shares of one of them is owned, directly or indirectly, by the other party or where the majority of stocks or shares in both parties are owned by one party. Related parties also include the person or persons who are subject to the actual control of another person. Actual control means every arrangement, agreement or ownership of stocks or shares, regardless of its percentage, in a manner that leads to the control of the management or decisions-taking.</p>
        <br />

        <h2><strong>FINANCIAL THRESHOLDS CALCULATION</strong></h2>
        <br />

        <p>The financial threshold for the Economic Concentration is calculated as per the sum of the achieved annual turnover or assets value as per the latest approved annual consolidated financial statement for all the Concerned Parties, including seller(s) who stays among the related parties of the target entity after the implementation of the Economic Concentration, this excludes seller(s) who will exit the target entity after the implementation of the Economic Concertation.</p>
        <br />
        <p>In case the annual consolidated turnover or assets value were in foreign currency it shall be converted to EGP as per the official exchange rate declared by the Central Bank of Egypt at the last day of the Concerned Party’s financial year.</p>
        <br />

        <h2><strong>WHO SHALL SUBMIT THE PRE-MERGER NOTIFICATION</strong></h2>
        <br />

        <p>The pre-merger notification should be filed before the ECA by the following person(s):</p>
        <br />

        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>The acquiring person(s) in case the acquisition leads to individual or collective control or material influence on other person(s).</li>
          <br />
          <li>The merged entities in case of merger.</li>
          <br />
          <li>The acquiring person(s) in case the purpose of such an acquisition is to establish a joint venture.</li>
          <br />
          <li>Parties establishing the joint venture.</li>
          <br />
        </ul>

        <h2><strong>STEPS FOR FILING PRE-MERGER NOTIFICATION</strong></h2><br />

        <p>There is no deadline for filing the pre-merger notification with the ECA, as the filing shall be made at any time prior to the implementation of the Economic Concentration, and according to the ER Amendments the steps for filing the pre-merger notification are as follows:</p>
        <br />

        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>Filling-in the ECA pre-merger notification application form (to be issued).</li><br />
          <li>Pay the relevant examination official fees to the ECA.</li><br />
          <li>The submission of photocopies of the documents detailed in the ER Amendments, noting that any non-Arabic document must be translated into Arabic in order to be accepted by the ECA.</li><br />
          <li>Upon the receipt of the pre-merger notification file, the ECA will publish a statement and a brief for the Economic Concentration in a daily newspaper or on its official website, in order for third parties to submit any related comments within 15 days from the publishing date, this is unless the ECA decides not to publish due to reasons related to public order. This publishing process was not referred to in the CLA, but it shall not delay the examination period.</li><br />
          <li>The applicant may submit any further document(s) or information that could be considered fundamental for the examination of the Economic Concentration such as studies prepared by the Concerned Parties for their products, or for the purpose of evaluating or analyzing the impact of the transaction on the market.</li><br />
          <li>The applicant must notify the ECA with any changes to the legal form of the Economic Concentration during the examination period, otherwise the approval granted in case of not notifying the ECA with such changes will be considered granted through the submission of false information/documents to the ECA.</li><br />
        </ul>

        <h2><strong>DOCUMENTS REQUIRED FOR THE PRE-MERGER FILING</strong></h2><br />

        <p>Pursuant to the ER Amendments, the applicant for the pre-merger notification shall submit photocopies of the following documents (any non-Arabic document must be translated into Arabic):</p>
        <br />

        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>Power of attorney issued in the name of the applicant’s representative(s) legalized before the relevant Egyptian Consulate, (the original should be available upon request), along with a copy of the representative(s) ID.</li>
          <br />
          <li>Commercial register extract or an equivalent document for each of the Concerned Parties excluding Related Parties.</li>
          <br />
          <li>A copy of the articles of association as amended or an equivalent document for each of the Concerned Parties excluding Related Parties.</li>
          <br />
          <li>A copy of the latest approved annual consolidated financial statements (Or, separate financial statement if the consolidated in not yet available) for the persons controlling each of the Concerned Parties along with the relevant auditor’s report.</li>
          <br />
          <li>A copy of the annual report for each of the Concerned Parties excluding related parties.</li>
          <br />
          <li>A copy of the letter of Intent, memorandum of understanding, sale and purchase agreement, purchase offer, or, the due diligence report, or, shareholders agreement, or, any other agreement that allows a person to control or have material influence.</li>
          <br />
          <li>A copy of the ratified meeting minutes for the board of directors and general ordinary/extraordinary assembly related to the Economic Concentration.</li>
          <br />
          <li>A copy of the approvals and clearances issued in relation to the notified Economic Concentration from other authorities inside or outside of Egypt.</li>
          <br />
          <li>The payment receipt for the ECA examination fees</li>
          <br />
          <li>Written acknowledgment that the applicant will bear any publication fees (the ECA board should issue regulation regarding such fees).</li>
          <br />
        </ul>

        <h2><strong>PRE-MERGER EXAMINATION PROCESS</strong></h2><br />

        <p>Upon the submission of the pre-merger filing, the Economic Concentration will be examined by the ECA committee which can (but doesn’t have to) involve two phases:</p>
        <br />
        <p><strong>Phase 1:</strong></p><br />

        <p>The ECA committee will review the filing and render a decision within 30 working days, which can be extended by an additional 15 working days if the Concerned Parties submitted an undertakings and regulations proposal during the examination period to obtain a conditional approval for the Economic Concentration from the ECA.</p>
        <br /><p>If no decision was rendered by the committee in Phase 1 within a maximum of 45 working days from the date of submitting the notification’s, the Economic Concentration should be deemed approved by the ECA.</p>
        <br />

        <p><strong>Phase 2:</strong></p><br />
        <p>The ECA committee in Phase 1 may decide to refer the notification for a Phase 2 (inspection). In this phase the committee must render a decision within 60 working days from the referral decision, which can be extended by additional 15 working days if the Concerned Parties submitted an undertakings and regulations proposal during the examination period to obtain a conditional approval for the Economic Concentration from the ECA.</p>
        <br />
        <p>If the ECA during, Phase 2, does not issue a decision within a maximum of 75 working days from the referral of the filing from Phase 1, the Economic Concentration should be deemed approved by the ECA.</p>
        <br />

        <h2><strong>EXAMINATION/FILING FEES</strong></h2><br />
        <p>Unlike the post-closing filing regime, which was free of charge, the Pre-Merger Regime includes associated fees, as the ECA will apply an official fee to be paid by the applicant and can be categorized as follows:</p>
        <br />
        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>EGP 80,000 if the combined total annual turnover or assets for the Concerned Parties in Egypt falls between EGP 90 million and EGP 1 billion.</li>
          <br />
          <li>EGP 90,000 if the combined total annual turnover or assets for the Concerned Parties in Egypt falls between EGP 1 billion and EGP 1.5 billion.</li>
          <br /><li>EGP 100,000 if the combined total annual turnover or assets for the Concerned Parties in Egypt exceeds EGP 1.5 billion.</li>
          <br /><li>EGP 100,000 if the total global(worldwide) combined annual turnover or assets of the Concerned Parties exceeds EGP 7.5 billion according to the latest approved consolidated financial statements under the condition that the total annual Egyptian turnover for one of the Concerned Parties of the Economic Concentration in its latest approved annual consolidated financial statements exceeded EGP 200 million.</li>
        </ul><br />

        <h2><strong>PENALTIES</strong></h2><br />
        <p>Failure to observe the Pre-Merger Regime can result in a fine of between 1 percent and 10 percent of the value of the gross turnover or assets for the Concerned Parties, based on the latest approved consolidated financial reports, and in the event such calculation is not possible the fine will range between EGP 30 million to EGP 500 million.</p>
        <br />
        <h2><strong>EXAMINATION FOR BELOW-THRESHOLD ECONOMIC CONCENTRATION</strong></h2><br />
        <p>The ER Amendments provides that ECA has the right – after obtaining the Prime Minister approval - to start the examination of an Economic Concentration that does not meet the financial thresholds for the pre-merger notification, in the event that the ECA has an evidence that this Economic Concentration may lead to restricting or limiting or harming the competition within one year from its implementation, and this can be evidenced by the occurrence of any of the following cases:</p>
        <br />
        <ul style={{ listStyleType: 'disc', color: '#555555', paddingLeft: '20px' }}>
          <li>Limiting the technological or innovation development.</li>
          <br /><li>Market control by any act that increases or reduces prices.</li>
          <br /><li>Reduce the quality of the products.</li>
          <br /><li>Creating barriers from entering the market or expanding in it.</li>
        </ul>
        <br />

        <h2><strong>EXEMPTED TRANSACTIONS</strong></h2><br />
        <p>The CLA has listed certain transactions that are exempted from the Pre-Merger Regime:</p>
        <br /><ul>
          <li>Temporary acquisitions, made by securities companies, of securities with the intention to resell within one year from their acquisition date, if the securities company does not exercise any voting rights or take any actions or measures that would influence strategic decisions or the commercial objectives of the acquired entity; moreover, the ECA may consider requests to extend this period under certain conditions.</li>
          <br /><li>A merger or acquisition between subsidiaries as this process is considered as a form of restructuring unless such merger or acquisition will result in a direct or indirect change in control or material influence.</li>
        </ul>
        <br />
        <h2><strong>FIRMS REGULATED BY THE EGYPTIAN FINANCIAL REGULATORY AUTHORITY</strong></h2>
        <br /><p>The Financial Regulatory Authority (FRA) supervises Egyptian companies that perform capital market, nonbanking financial institution, and insurance-related activities. The CLA establishes a different path for pre-closing notifications for economic concentrations that occur in any FRA-supervised activities. Such parties must pre-notify the FRA of the economic concentration, and the FRA must seek an ECA opinion before approving implementation of the economic concentration. In this situation, the ECA is obliged to render a decision within a maximum of 30 calendar days from the date of receiving the notification from the FRA. Otherwise, it will be deemed that the ECA has no objection to this economic concentration.</p>

      </div>
    </>
  );
};

export default InternationalFinanceText;
