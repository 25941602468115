import React from 'react';

const DataPrivacyText = () => {

  const items = [
    "Empowered Data Subjects: Individuals are afforded increased control over their personal information through the legal provisions, granting rights such as access, rectification, deletion, and the ability to restrict processing. Additionally, the law enables individuals to exercise the right to data portability, facilitating the smooth transfer of their data between different service providers. This empowers individuals with practical tools to manage and move their information as needed.",
    "Strengthened Security Measures: The law mandates data controllers to establish suitable technical and organizational safeguards, aiming to shield personal data from unauthorized access, disclosure, loss, or damage. Furthermore, the inclusion of data breach notification requirements ensures that individuals are promptly informed in the event of security incidents, emphasizing transparency and accountability in data protection measures.",
    "Accountability through Data Protection Officers: As per the legal requirements, organizations are obligated to appoint Data Protection Officers (DPOs). These individuals play a crucial role in overseeing compliance with the regulations and act as intermediaries between data controllers and data subjects. Their responsibilities involve ensuring that the data processing activities align with the established rules, fostering transparency, and facilitating communication in the realm of data protection."
  ]

  const items2 = [
    "Technological Upgrades: Data controllers may need to invest in updated security technologies and data management systems to meet the law's stringent requirements.",
    "Raising Awareness: Building public awareness and understanding of the DPL and individual rights is crucial for its successful implementation."
  ]

  const items3 = [
    "Enhanced Competitiveness: Highlighting robust data privacy practices can positively influence a company's reputation and potentially attract new customers in a world driven by data. Moreover, when it comes to innovation and efficiency, the implementation of strong data governance practices goes beyond a marketing perspective. It can genuinely streamline operations, reduce security risks, and contribute to building trust within the digital ecosystem. Embracing these practices becomes not only a marketing advantage but also a strategic move towards enhancing overall organizational resilience in the evolving landscape of data management.",
    "Building a Culture of Privacy: The DPL establishes a foundation for a future where data plays a significant role, emphasizing the importance of respecting individual privacy within Egyptian society. It reflects a societal commitment to integrating privacy considerations into the broader framework of data usage, acknowledging the evolving landscape of technology and its impact on personal information. Understanding the implications of this legal framework is crucial for individuals and organizations alike as they navigate the unfolding dynamics of a data-driven future in Egypt."
  ]

  const items4 = [
    "Conducting Data Audits: Evaluating current data practices and pinpointing areas that may need adjustments is pivotal for attaining compliance. This process involves a practical examination of how data is handled, recognizing potential gaps, and addressing them to align with regulatory requirements. It's a fundamental step toward ensuring that organizational practices align with the evolving standards and expectations in the realm of data management.",
    "Developing Data Governance Policies: Implementing robust data governance policies and procedures aligned with the law's requirements is essential.",
    "Seeking Expert Guidance: Seeking guidance and support from experienced data privacy professionals proves valuable during the compliance process. Egypt's ongoing development in data protection signifies a notable milestone in its digital progression. By embracing the principles laid out, businesses and organizations not only ensure compliance but also tap into the potential of a data-driven future marked by trust, transparency, and a commitment to individual privacy. This stride places Egypt at the forefront of a global movement aiming to establish a more secure and equitable digital landscape for everyone."
  ]

  return (
    <div
      style={{
        fontSize: '22px',
        fontWeight: '400',
        lineHeight: '1.2',
        
        fontFamily: 'Times New Roman, Times, serif',
        color: "#555555"
      }}>
      <p style={{ marginBottom: '10px', fontSize: 22 }}>
        <strong>A New Dawn for Data Privacy: Unveiling Egypt's Evolving Regulatory Landscape</strong>
      </p>

      <p style={{ marginBottom: '20px' }}>
        In the constantly evolving digital landscape, the responsibility of handling personal data has become a significant focal point. Acknowledging this, Egypt is on the verge of introducing a comprehensive data privacy law, signaling a significant step towards enhanced data protection. On July 17, 2020, Egypt issued its long-awaited Data Protection Law no. 151 for 2020 (“DPL”), reflecting a firm dedication to aligning with global standards and representing a noteworthy development for businesses and organizations within the country.
        <br /><br />
        In the current digital age, the way personal information is handled has undergone substantial changes. With online transactions and social media interactions, our digital footprints now contribute to a more detailed portrayal of our lives. Given this growing collection of data, there is a pressing need for strong legal frameworks to safeguard individuals from potential misuse, infringement, and exploitation.
        <br /><br />
        The legal framework outlines essential principles that guide the processing of data, covering aspects such as collection, storage, and utilization. Emphasizing transparency, purpose limitation, data minimization, and accountability, these principles form the foundation for responsible data management.
      </p>

      <ol>
        {items.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) </strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Challenges and Opportunities</strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The introduction of the DPL marks a notable step forward for data privacy in Egypt. However, as organizations engage with its intricacies, they are likely to encounter both challenges and opportunities.
        <br /><br />
        One notable aspect involves compliance burdens, which may pose initial challenges for businesses, especially small and medium-sized enterprises, as they strive to adjust to the new regulations and put in place the necessary compliance measures. This period of adjustment reflects the practical aspects that organizations may navigate during the implementation of the DPL.
      </p>

      <ol>
        {items2.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) </strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <h2 style={{ marginBottom: '20px' }}><strong>The Silver Lining: Building Trust in the Digital Age</strong></h2>

      <p style={{ marginBottom: '20px' }}>
        Despite the challenges, the DPL presents a pool of opportunities:
      </p>

      <ol>
        {items3.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) </strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <h2 style={{ marginBottom: '20px' }}><strong>A Proactive Approach to Compliance</strong></h2>

      <p style={{ marginBottom: '20px' }}>As organizations prepare for this paradigm shift, a proactive approach is key to successful adaptation:
      </p>

      <ol>
        {items4.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) </strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <p style={{ marginBottom: '20px' }}>
        The DPL was issued on July 13, 2020, and came into force on October 16, 2020, but a compliance grace period of one year is granted from the date of the issuance of the Executive Regulations. However, up until today, the Executive Regulations of the DPL have not been issued in order for the law to be fully enforceable.
      </p>

    </div>
  );
};

export default DataPrivacyText;
