const MainPathConstants = {
  LANDING: '/',
  CONTACT: '/Contact',
  ABOUT: '/About',
  DENTONS: '/Cairo Office Association',
  PRACTICE_AREAS: '/Practice Areas',
  PA: '/PA',
  SEARCH_PAGE : '/Results'
};

export default MainPathConstants;
