const TeamPathConstants = {
  MAIN: '/Professionals',
  PROFILE: '/Professionals/:profileName',
  HABIL_ABU_EL_KHEIR: '/Professionals/habil-abu-el-kheir',
  TAMER_KAMAL: '/Professionals/tamer-kamal',
  MOHAMED_TAYEL: '/Professionals/mohamed-tayel',
  HOSSAM_EL_SHARAWI: '/Professionals/hossam-el-sharawi',
  GAMIL_DORGHAM: '/Professionals/gamil-dorgham',
  MAHMOUD_MAHER: '/Professionals/mahmoud-maher',
  ADEL_FEKRY: '/Professionals/adel-fekry',
  ALIA_SAFEI: '/Professionals/alia-safei',
  ANDREW_AFIFI: '/Professionals/andrew-afifi',
  ASSER_NEGM_EL_DIN: '/Professionals/asser-negm-el-din',
  EMIL_GHOBRIAL: '/Professionals/emil-ghobrial',
  AMIN_KAMAL_AMIN: '/Professionals/amin-kamal-amin',
  WALID_SAKR: '/Professionals/walid-sakr',
  ADEL_RASHEED: '/Professionals/adel-rasheed',
  EMIL_LABIB_HANA: '/Professionals/emil-labib-hana',
  GAMAL_ABDEL_RAZAK: '/Professionals/gamal-abdel-razak',
  HANY_EDWARD: '/Professionals/hany-edward',
  NANCY_NASHAT: '/Professionals/nancy-nashat',
  MARY_RIZKALLA: '/Professionals/mary-rizkalla',
  BESHOY_MELAD: '/Professionals/beshoy-melad',
  FADY_SEIF: '/Professionals/fady-seif',
  AHMED_RASHEED: '/Professionals/ahmed-rasheed',
  ABANOUB_THARWAT: '/Professionals/abanoub-tharwat',
  MAHROUS_SHAFIK: '/Professionals/mahrous-shafik',
  AMAL_AFIFI: '/Professionals/amal-afifi',
  AYMAN_ELSISI: '/Professionals/ayman-elsisi',
  HABIBA_OSAMA: '/Professionals/habiba-osama',
};

export default TeamPathConstants;
