/* eslint-disable no-unused-vars */
import React from 'react';
import mainLogo from './Afifi - Logo - Black - 2023 (1).png';
import drawerLogo from './Vector (2).svg';
// import mobLogo from '../../../assets/Images/Misc/mobLogo.svg';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
// import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import newLogo from './White.png';
import { useState } from 'react';
import Search from '../../Common/Search/Search';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end', // Aligns content to the end (right)
    alignItems: 'flex-start', // Aligns content to the start (top)
    flexGrow: 1,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function BlackHeaderBar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isHoveredCareers, setIsHoveredCareers] = useState(false);

  const handleMouseEnterCareers = () => {
    setIsHoveredCareers(true);
  };

  const handleMouseLeaveCareers = () => {
    setIsHoveredCareers(false);
  };

  const [isHoveredCareers2, setIsHoveredCareers2] = useState(false);

  const handleMouseEnterCareers2 = () => {
    setIsHoveredCareers2(true);
  };

  const handleMouseLeaveCareers2 = () => {
    setIsHoveredCareers2(false);
  };
  const DrawerList = (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        background: '#09283F',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}>
      <Box
        sx={{
          width: '100vw',
          height: '10vh',
          background: '#09283F',
          position: 'absolute',
          top: 50,
          left: 0,
        }}>
        <IconButton href="/" disableRipple>
          <img
            src={newLogo}
            alt="Home"
            style={{
              width: '75%',
            }}
          />
        </IconButton>
      </Box>
      <IconButton
        disableRipple
        style={{
          color: 'white',
          paddingLeft: '20%',
          position: 'absolute',
          top: 75,
          right: 10,
        }}>
        <CloseIcon sx={{ fontSize: 40 }} />
      </IconButton>
      <List
        style={{
          paddingTop: '150px',
          position: 'absolute',
          top: 20,
          left: 20,
        }}>
        <ListItem
          key="Our Story"
          disablePadding
          onMouseEnter={handleMouseEnterCareers2}
          onMouseLeave={handleMouseLeaveCareers2}
          style={{
            width: '100%',
            paddingLeft: '45px',
            paddingRight: '75px',
          }}>
          <ListItemButton>
            <ListItemText
              primary="Our Story"
              style={{ color: 'white', textAlign: 'left' }}
              primaryTypographyProps={{
                fontSize: '25px',
                fontFamily: 'Montserrat',
              }}
            />
            <ListItemIcon>
              {<ArrowForwardIcon sx={{ color: 'white' }} />}
            </ListItemIcon>
          </ListItemButton>
          {/* Render additional items if hovered */}
          {isHoveredCareers2 && (
            <div
              style={{
                background: '#09283F',
                opacity: '81%',
                width: '631px',
                height: '200px',
                position: 'absolute',
                top: -60,
                left: isSmallScreen ? 50 : 320,
              }}>
              <Stack direction="row" spacing={5} style={{ paddingTop: 50 }}>
                <div>
                  <ListItemButton component="div" href="/About">
                    <p
                      style={{
                        fontFamily: 'Montserrat',
                        color: 'white',
                      }}>
                      About Us
                    </p>
                  </ListItemButton>
                  <ListItemButton
                    component="div"
                    href="/Cairo Office Association">
                    <p
                      style={{
                        fontFamily: 'Montserrat',
                        color: 'white',
                      }}>
                      Cairo Office Association
                    </p>
                  </ListItemButton>
                </div>
              </Stack>
            </div>
          )}
        </ListItem>
        {[
          'Insights',
          'Practice Areas',
          'Professionals',
          'Careers',
          'Contact',
        ].map((text, index) =>
          index === 1 ? (
            <ListItem
              key={index}
              disablePadding
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                width: '110%',
                paddingLeft: '45px',
                paddingRight: '45px',
              }}>
              <ListItemButton href={'/' + text}>
                <ListItemText
                  primary={text}
                  style={{
                    color: 'white',
                    textAlign: 'left',
                  }}
                  primaryTypographyProps={{
                    fontSize: '25px',
                    fontFamily: 'Montserrat',
                  }}
                />
                <ListItemIcon>
                  {<ArrowForwardIcon sx={{ color: 'white' }} />}
                </ListItemIcon>
              </ListItemButton>
              {/* Render additional items if hovered */}
              {isHovered && !isSmallScreen && (
                <div
                  style={{
                    background: '#09283F',
                    opacity: '81%',
                    width: '631px',
                    height: '400px',
                    position: 'absolute',
                    top: -172,
                    left: 380,
                  }}>
                  {/* Additional items here */}
                  <Stack direction="row" spacing={5} style={{ paddingTop: 50 }}>
                    <div>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/arbitration">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Arbitration
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/aviation">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Aviation
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/bankingAndFinance">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Banking & Finance
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/competition">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Anti-Trust & Competition
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/construction">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Construction
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/corporate">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Corporate
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/corporateGovernance">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Corporate Governance
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/Intellectual Property">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Intellectual Property
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/litigation">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Litigation
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/mergerAndAcquisition">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Mergers & Acquisitions
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/oilAndGas">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Oil & Gas
                        </p>
                      </ListItemButton>
                    </div>
                    <div>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/energy">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Energy
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/employment">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Employment
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/realEstate">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Real Estate
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/renewableEnergy">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Renewable Energy
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/technology">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          Technology
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/practiceAreas/whiteCollarCrime">
                        <p style={{ fontFamily: 'Montserrat', color: 'white' }}>
                          White-collar Crime
                        </p>
                      </ListItemButton>
                    </div>
                  </Stack>
                </div>
              )}
            </ListItem>
          ) : text === 'Careers' ? (
            <ListItem
              key="Careers"
              disablePadding
              onMouseEnter={handleMouseEnterCareers}
              onMouseLeave={handleMouseLeaveCareers}
              style={{
                width: '100%',
                paddingLeft: '45px',
                paddingRight: '75px',
              }}>
              <ListItemButton href="/Careers">
                <ListItemText
                  primary="Careers"
                  style={{ color: 'white', textAlign: 'left' }}
                  primaryTypographyProps={{
                    fontSize: '25px',
                    fontFamily: 'Montserrat',
                  }}
                />
                <ListItemIcon>
                  {<ArrowForwardIcon sx={{ color: 'white' }} />}
                </ListItemIcon>
              </ListItemButton>
              {/* Render additional items if hovered */}
              {isHoveredCareers && !isSmallScreen && (
                <div
                  style={{
                    background: '#09283F',
                    opacity: '81%',
                    width: '631px',
                    height: '150px',
                    position: 'absolute',
                    top: -60,
                    left: 320,
                  }}>
                  <Stack direction="row" spacing={5} style={{ paddingTop: 50 }}>
                    <div>
                      <ListItemButton component="div" href="/careers/interns">
                        <p
                          style={{
                            fontFamily: 'Montserrat',
                            color: 'white',
                          }}>
                          Interns
                        </p>
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        href="/careers/professionals">
                        <p
                          style={{
                            fontFamily: 'Montserrat',
                            color: 'white',
                          }}>
                          Professionals
                        </p>
                      </ListItemButton>
                    </div>
                  </Stack>
                </div>
              )}
            </ListItem>
          ) : (
            <ListItem
              key={text}
              disablePadding
              style={{
                width: '100%',
                paddingLeft: '45px',
                paddingRight: '45px',
              }}>
              <ListItemButton href={'/' + text}>
                <ListItemText
                  primary={text}
                  style={{
                    color: 'white',
                    textAlign: 'left',
                  }}
                  primaryTypographyProps={{
                    fontSize: '25px',
                    fontFamily: 'Montserrat',
                  }}
                />
                {/* <ListItemIcon>
                {<ArrowForwardIcon sx={{ color: 'white' }} />}
              </ListItemIcon> */}
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  return isSmallScreen ? (
    <Stack
      direction="row"
      spacing={2}
      pt={2}
      style={{ justifyContent: 'space-between' }}>
      <IconButton href="/">
        <img src={mainLogo} alt="Home" style={{ width: '125px' }} />
      </IconButton>

      <Search
        key={'Search-Header'}
        inputColor="black"
        isSmallScreen={isSmallScreen}
      />

      <div>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon style={{ color: 'black' }} />
        </IconButton>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
    </Stack>
  ) : (
    <Stack direction={'row'} spacing={2} pr={5} pt={5} pl={5}>
      <div>
        <IconButton href="/" disableRipple>
          <img
            src={mainLogo}
            alt="Home"
            style={{ width: '200px', height: '75%' }}
          />
        </IconButton>
      </div>
      <Search
        key={'Search-Header'}
        inputColor="black"
        isSmallScreen={isSmallScreen}
      />
      <div className={classes.root}>
        <IconButton onClick={toggleDrawer(true)}>
          <img src={drawerLogo} alt="Icon" />
        </IconButton>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </Stack>
  );
}
