import React from 'react';

const LegalPerspectiveText = () => {
  return (<div
    style={{
      fontSize: '22px',
      fontWeight: '400',
      lineHeight: '1.2',
      
      fontFamily: 'Times New Roman, Times, serif',
      color: "#555555"
    }}>

    <p style={{ marginBottom: '10px', fontSize: 22 }}>
      <strong>New International Arbitration Centre Launches in Abu Dhabi (ArbitrateAD)</strong>
    </p>

    <p style={{ marginBottom: '20px' }}>
      In a significant development for arbitration in Abu Dhabi, the Abu Dhabi Commercial Conciliation and Arbitration Centre (ADCCAC) has been officially replaced by the newly launched Abu Dhabi International Arbitration Centre, styled as arbitrateAD. This transition, announced by the Abu Dhabi Chamber of Commerce and Industry in December 2023, marks a pivotal moment in the Emirate's arbitration landscape, introducing modernized rules and procedures aimed at enhancing the efficiency and attractiveness of arbitration in the region.    </p>

    <p style={{ marginBottom: '20px' }}>
      Under the arbitrateAD framework, several notable changes are set to redefine arbitration practices in both 'onshore' and 'offshore' Abu Dhabi. Firstly, arbitrateAD will supersede ADCCAC as the primary arbitration institution for 'onshore' Abu Dhabi. Parties previously subject to ADCCAC rules will now be deemed to have adopted arbitrateAD rules, provided that proceedings have not already commenced under the former regime.    </p>

    <p style={{ marginBottom: '20px' }}>
      Additionally, arbitrateAD will coexist with the International Chamber of Commerce (ICC) case management office in the Abu Dhabi Global Market (ADGM), the offshore common law jurisdiction within Abu Dhabi. Notably, arbitrateAD establishes ADGM as the default seat, leveraging its modern regulatory framework and pro-arbitration environment to facilitate enforceability of ADGM-seated awards internationally.    </p>

    <p style={{ marginBottom: '20px' }}>
      The arbitrateAD rules introduce several key features aimed at streamlining arbitration proceedings and aligning with international standards. These include provisions for early dismissal of cases lacking legal merit or falling outside the tribunal's jurisdiction, a timeline for issuing awards within nine months from the initial case management conference, and mechanisms for joinder, consolidation, and emergency arbitration. Notably, expedited arbitration provisions cater to disputes below a certain threshold, while requirements for disclosure of third-party funding enhance transparency.</p>

    <p style={{ marginBottom: '20px' }}>
      The composition of the arbitrateAD Court, comprising eminent practitioners with diverse expertise, underscores the center's commitment to excellence and diversity. The Court includes prominent figures such as Lord Peter Goldsmith, Hassan Arab, Judge Awn Shawkat Al-Khasawneh, and Michael Schneider, with nearly half of its members being women.    </p>

    <p style={{ marginBottom: '20px' }}>
      The establishment of arbitrateAD adds to a series of recent initiatives aimed at modernizing arbitration in the UAE. Building upon the introduction of the UAE Federal Arbitration Law in 2018 and the consolidation of arbitration centers in Dubai in 2022, arbitrateAD signals the Emirate's commitment to fostering a robust and internationally recognized arbitration ecosystem.    </p>

    <p style={{ marginBottom: '20px' }}>
      With its rejuvenated center and updated rules, arbitrateAD is poised to attract a diverse range of contracting parties, including those previously inclined towards more established international arbitration rules. As arbitrateAD begins to build its caseload, stakeholders eagerly anticipate its role in further enhancing Abu Dhabi's arbitration landscape and its broader implications for the region's legal environment.    </p>

  </div>
  );
};

export default LegalPerspectiveText;
