const InsightsPathConstants = {
  PERSONAL_RELATED_INSIGHTS: '/Insights/:insightName',
  MAIN: '/Insights',
  LABOR_LAW: '/Insights/laborLaw',
  COP: '/Insights/conferenceOfTheParties',
  RENEWABLE_ENERGY: '/Insights/renewableEnergy',
  DATA_PRIVACY: '/Insights/dataPrivacy',
  SUSTAINABLE_MOBILITY: '/Insights/sustainableMobility',
  TAXING_CATALYST: '/Insights/taxingCatalyst',
  CAPITAL_RETENTION: '/Insights/capitalRetention',
  SUSTAINABLE_FUELS: '/Insights/sustainableFuels',
  GAFI: '/Insights/gafi',
  ENERGY_GLOBAL_DEMAND: '/Insights/energyGlobalDemand',
  ARBITRATION_RESOLVING: '/Insights/arbitrationResolving',
  CONFERENCE_OF_THE_PARTIES: '/Insights/conferenceOfTheParties',
  CONSTRUCTION_TRENDS: '/Insights/constructionTrends',
  CRCICA_RULES: '/Insights/crcicaRules',
  HYDROGEN_INITIATIVES: '/Insights/hydrogenInitiative',
  INTERNATIONAL_FINANCE: '/Insights/internationalFinance',
  LEGAL_PERSPECTIVE: '/Insights/legalPerspective',
  MERGER_CONTROL: '/Insights/mergerControl',
  RENEWABLE_ENERGY_FUTURE: '/Insights/renewableEnergyFuture',
  TRANSPORTATION_LOGISTICS: '/Insights/transportationLogistics',
  AVIATION: '/Insights/aviation',
};

export default InsightsPathConstants;
