import React from 'react';

const MergerControlText = () => {

  const items = [
    "The merging of one or more persons with an existing person who maintains his legal personality after the merge, or establishment of a new person through merging of at least two previously independent persons which shall result in the termination of its legal personality or any of its parts.",
    "Direct or indirect acquisition by one or more persons of the control or material influence over another person or part thereof by virtue of a contract, or through the purchase of securities, assets or other means, and the acquisition may take place individually or collectively.",
    "Establishment of a joint venture or the acquisition by two or more persons of an existing entity for the purpose of establishing a joint venture that carries out economic activity independently and permanently."
  ]

  const items2 = [
    "Accumulative value of the annual Egyptian turnover or assets located in Egypt for all the concerned parties of the Economic Concentration exceeds EGP 900 million as per the latest approved consolidated annual financial statement, under the condition that the Egyptian annual turnover of at least two concerned parties (each one separately) exceeds EGP 200 million as per its latest approved consolidated annual financial statement.",
    "Accumulative value of the annual worldwide turnover or assets located worldwide for all the concerned parties of the Economic Concentration exceeds EGP 7.5 billion as per the latest approved consolidated annual financial statements, under the condition that the value of the Egyptian turnover for at least one party exceeds EGP 200 million as per its latest approved consolidated annual financial statement."
  ]

  const items3 = [
    "In this phase, the committee will review the filing and render a decision within 30 working days that can be extended by an additional 15 working days in case the parties submit undertakings and regulations proposal pursuant to the CLA Executive Regulation. If no decision was rendered by the committee in Phase 1 within a maximum of 45 working days from the date of submission, the Economic Concentration should be deemed approved by the ECA.",
    "The ECA committee in Phase 1 may decide upon the referral of the filing to Phase 2 inspection. In this phase, the committee shall render a decision within 60 working days from the referral decision that can be extended by an additional 15 days in case the concerned parties submit undertakings and regulations proposal pursuant to the CLA Executive Regulation. If no decision was made by the ECA committee in Phase 2 within a maximum of 75 working days from the referral of the filing from Phase 1, the Economic Concentration should be deemed approved by the ECA."
  ]

  const items4 = [
    "Temporary acquisition made by securities companies on securities with the intention to resell it within one year from the acquisition date, under the condition that such companies do not exercise any voting rights or take any action or measure that would influence strategic decisions or the commercial objectives of the acquired person, and the ECA may consider the request to extend this period under certain conditions.",
    "The occurrence of a merger or acquisition between subsidiaries as this process is considered a kind of restructuring unless such merger or acquisition will result in a direct or indirect change in control or material influence."
  ]

  return (
    <div
      style={{
        fontSize: '22px',
        fontWeight: '400',
        lineHeight: '1.2',
        
        fontFamily: 'Times New Roman, Times, serif',
        color: "#555555"
      }}>

      <p style={{ marginBottom: '10px', fontSize: 22 }}>
        <strong>Reshaping the Landscape: Transformative Changes in Egypt’s Merger Control Framework</strong>
      </p>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Introduction</strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        Merger control is one of the competition law enforcement tools worldwide, and Competition/Antitrust matters in Egypt are governed by the Competition Law no. 3 for 2005 (“<strong>COL</strong>”). On 29 December 2022, Law no. 172 for 2022 regarding COL Amendments (“<strong>CLA</strong>”) was published, and it introduced the Pre-Closing Merger Control Regime (the “<strong>Pre-Merger Regime</strong>”), which replaced the previous post-closing merger control regime.
      </p>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Enforceability of the Pre-Merger Regime</strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The CLA is deemed enforceable as from 30 December 2022. Accordingly, from a legal perspective, it should not have a retroactive effect. It is important to note that any transactions closed prior to this date will be subjected to the post-closing merger notification according to the statement published by the Egyptian Competition Authority (“<strong>ECA</strong>”) on 4 January 2023. However, we believe that the actual enforcement of the Pre-Merger Regime will be after the issuance of the COL Executive Regulations amendments, which shall provide answers to fundamental aspects such as the method(s) for calculations of (i) the new threshold triggering the filing obligation and (ii) the filing official fees. It is also expected that these amendments will be accompanied by new guidelines and notification form prepared by the Egyptian Competition Authority (“<strong>ECA</strong>”).
      </p>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Transactions subjected to Pre-Merger Regime
        </strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        Pursuant to the CLA, the Pre-Merger Regime applies to any Economic Concentration, which is defined as any change in control or material influence of one or more persons, resulting from any of the following:
      </p>

      <ol>
        {items.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) {index === 0 && <strong>Mergers:</strong> || index === 1 && <strong>Acquisition:</strong> || index === 2 && <strong>Joint Venture:</strong>}</strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Thresholds that Trigger the Filing Obligation</strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The CLA has set two thresholds that trigger the obligation for filing under the Pre-Merger Regime, which are as follows:
      </p>
      <ol>
        {items2.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) </strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <p style={{ marginBottom: '20px' }}>
        The process of calculating the annual turnover and assets should be demonstrated in the COL Executive Regulations amendments which are expected to be published soon.
      </p>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Examination Process
        </strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The Economic Concentration will be reviewed by an ECA committee, and it could be subjected to up to two phases of examination:      </p>


      <ol>
        {items3.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) {index === 0 && <>Phase 1: <br /><br /></> || index === 1 && <>Phase 2: <br /><br /></>}</strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Exempted Transactions
        </strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The CLA has listed certain transactions that will be exempted from the application of the Pre-Merger Regime, which are:</p>

      <ol>
        {items4.map((item, index) => (
          <li key={index} style={{ marginBottom: '20px' }}>
            <strong>{String.fromCharCode(97 + index)}) </strong> {item} {/* Using Unicode for alphabets */}
          </li>
        ))}
      </ol>

      <h2 style={{ marginBottom: '20px' }}>
        <strong>Companies Regulated by the Financial Regulatory Authority
        </strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The Financial Regulatory Authority (“FRA”) is the regulatory authority which supervises the Egyptian companies that perform capital market, non-banking financial institutions, and insurance-related activities. The CLA established a different path for pre-closing filing for Economic Concentrations that occur in any of the activities supervised by the FRA. In such cases, the relevant parties shall pre-notify the FRA with the Economic Concentration, and the FRA must seek the ECA opinion before approving the implementation of the Economic Concentration. The ECA shall render a decision within a maximum of 30 calendar days from the date of receiving the filing from the FRA; otherwise, it will be deemed as the ECA having no objection to this Economic Concentration.</p>


      <h2 style={{ marginBottom: '20px' }}>
        <strong>Filing Official Fees
        </strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The pre-closing filing regime will not be free of charge as the post-closing filing regime, as the ECA will apply an official fee capped at EGP 100,000 to be paid by the applicant as a fee for the examinations made by the ECA. The basis for calculation of these official fees will be clarified in the amendments that will be introduced to the COL Executive Regulations.</p>


      <h2 style={{ marginBottom: '20px' }}>
        <strong>Failing to Comply with the Pre-Closing Filing Obligation
        </strong>
      </h2>

      <p style={{ marginBottom: '20px' }}>
        The penalty for breaching the pre-closing filing obligation is a fine ranging between 1% - 10% of the value of the gross turnover or assets for the concerned parties according to the latest approved consolidated financial reports, and in the event such calculation is not possible, the fine will range between EGP 30 – 500 million.</p>

    </div>
  );
};

export default MergerControlText;
